//import Navbar from './components/navbar.js';
//import Menu from './menu.js';
//import Hero from './hero'
//import Footer from './components/footer.js'
//import Articles from './components/articles.js'
//import React, { useState, useEffect } from 'react';
//import axios from 'axios';
//import './style.scss'
//import './header.scss';
//import base from './data.json';
import React, { useState, useEffect } from "react";
function Page(props) {
    const imgSrc = "https://awd.rs/frontend/web/uploads/master/" + props.img

    const [title, setTitle] = useState('');
    const titleElement = document.createElement('title');

    // A�urirajte sadr�aj naslova
    useEffect(() => {
        titleElement.textContent = props.title;
        document.head.appendChild(titleElement);
        //  document.querySelector('title').textContent = title;
    }, [title]);
    
    return (
        <>
           
            <header className="App-main-header">
                <figure>
                    <img id="blur-img" src={imgSrc} title="Web dizajn - programiranje - SEO - internet-marketing" alt="Web dizajn - programiranje - SEO optimizacija | AWD Dizajn" className="blur-img" />
                </figure>
                <div>
                    <div className="mh-left">
                    </div>
                    <div className="mh-right">

                        <h1>{props.title}</h1>
                        <div itemProp="description">
                            <div dangerouslySetInnerHTML={{ __html: props.text }} />
                        </div>
                    </div>
                </div>
            </header>


            <ul>
                {JSON.parse(props.slave).map(slave => (
                    <li className="" key={slave.id}>
                        {slave.title ? (
                            <header>
                                <h2>{slave.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: slave.text }} />
                                {slave.subsubslaves && <a href={`/${props.link}/${slave.link}`}> {slave.title} </a>}
                              
                            </header>
                        ):(
                        <div dangerouslySetInnerHTML={{ __html: slave.text }} />
                        )}
                        
                        {slave.image && <img style={{ width:'100%' }} with="300" height="300" src={`https://awd.rs/frontend/web/uploads/master/${slave.image}`} alt={slave.title} />}
                                                                    
                    </li>
                ))}
            </ul>
           
        </>
        )
}
export default Page