//import Navbar from './components/navbar.js';
//import Menu from './menu.js';
//import Hero from './hero'
//import Footer from './components/footer.js'
//import Articles from './components/articles.js'
//import React, { useState, useEffect } from 'react';
//import axios from 'axios';
//import './style.scss'
//import './header.scss';
//import base from './data.json';
import React, { useState, useEffect } from "react";
//‍import { Helmet } from 'react-helmet';
import Helmet from "helmet";
function Page(props) {
    const imgSrc = "https://awd.rs/frontend/web/uploads/master/" + props.img;
    //const [data, setData] = useState([]);
   
    //const [title, setTitle] = useState('');
    const titleElement = document.createElement('title');

    //const [description, setDescription] = useState('');
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";

    const ogTitle = document.createElement("meta");
    ogTitle.setAttribute('property', 'og:title');
    
 

    
    useEffect(() => {
        titleElement.textContent = props.title;
        metaDescription.content = props.description;
        ogTitle.content = props.title;
      
        document.head.appendChild(titleElement);
        document.head.appendChild(metaDescription);
        document.head.appendChild(ogTitle);

    }, []);



    
    return (
        <>
            <Helmet>‍
                <title>Pets - Products</title>‍
                <meta name="description" content="Find all the best quality           products your pet may need" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content="Pets - Products" />
                <meta name="twitter:description" content="Best Products for your pet" />
                <meta name="twitter:image" content="url_to_image" />
                <meta property="og:title" content="Pets - Products" />
                <meta property="og:description" content="Best Products for your pet" />
                <meta property="og:image" content="url_to_image"/>
                <meta property="og:url" content="pets.abc" />
                <meta property="og:site_name" content="Pets - Products" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
            </Helmet>
           
            <header className="App-main-header">
                <figure>
                    <img id="blur-img" src={imgSrc} title="Web dizajn - programiranje - SEO - internet-marketing" alt="Web dizajn - programiranje - SEO optimizacija | AWD Dizajn" className="blur-img" />
                </figure>
                <div>
                    <div className="mh-left">
                    </div>
                    <div className="mh-right">

                        <h1>{props.title}</h1>
                        <div itemProp="description">
                            <div dangerouslySetInnerHTML={{ __html: props.text }} />
                        </div>
                    </div>
                </div>
            </header>
            

                    {/**
                    <Hero img={imgSrc}
                    title={props.title}
                    description = {props.text} />
            <header className="App-main-header">
                <figure>
                    <img id="blur-img" src={imgSrc} title="Web dizajn - programiranje - SEO - internet-marketing" alt="Web dizajn - programiranje - SEO optimizacija | AWD Dizajn" className="blur-img"/>
                </figure>
                <div>
                    <div className="mh-left">
                    </div>
                    <div className="mh-right">
                        
                        <h1>{props.title}</h1>
                        <div itemProp="description">
                            <div dangerouslySetInnerHTML={{ __html: props.text }} />
                        </div>
                    </div>
                </div>
            </header>
             */}


                <ul>
                {JSON.parse(props.slave).map(slave => (
                    <li className="" key={slave.id}>
                        {slave.title  ? (
                            <header>
                                <h2>{slave.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: slave.text }} />
                                {slave.subslaves && <a href={`/${props.link}/${slave.link}`}>{ slave.title}</a>}                                                                    
                 
                              
                            </header>
                        ):(
                        <div dangerouslySetInnerHTML={{ __html: slave.text }} />
                        )}                        
                        {slave.image && <img style={{ width:'100%' }} with="300" height="300" src={`https://awd.rs/frontend/web/uploads/master/${slave.image}`} alt={slave.title} />}                                                                    
                    </li>
                ))}
            </ul>
           
        </>
        )
}
export default Page