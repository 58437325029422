import {  
    Routes,
    Route,   
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import Menu from './menu.js';

import Page from './page.js';
import Subpage from './subpage.js';

import axios from 'axios';
//import Helmet from "helmet";
function App() {
    const [data, setData] = useState([]);

   
    
   
    useEffect(() => {
        // console.log(title);
        
        axios.get('https://js.awd.rs/api')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (

        <>
            <Menu menu={data} /> 
            <Routes>
                {data.map(item => (
                    <React.Fragment key={`item-${item.id}`}>
                        <Route
                            key={`route-${item.id}`}
                            path={item.link_sr_latn === 'web' ? '/' : item.link_sr_latn}
                            element={<Page description={ item.description_sr_latn} link={item.link_sr_latn} title={item.title_sr_latn} text={item.text_sr_latn} img={item.image} slave={item.slave_data} />}
                        />
                        {JSON.parse(item.slave_data).map(slave => (
                            slave.link ? (
                                <Route
                                    key={`route-${item.id}-${slave.id}`}
                                    path={`/${item.link_sr_latn}/${slave.link}`}
                                    element={<Subpage link={slave.link} title={slave.title} text={slave.text} img={slave.image} slave={slave.subslaves} />}
                                />
                            ) : null
                        ))}
                    </React.Fragment>
                ))}
            </Routes>
        </>
           
    );
}

export default App;
